<template>
    <b-modal ref="modal" :title="title" centered no-close-on-backdrop @cancel="makeEmptyObject" @close="makeEmptyObject" @hide="makeEmptyObject">
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Ustanova">
                    <validation-provider #default="{errors}" name="Ustanova" rules="required|max:50">
                        <b-form-input v-model="institutionObject.institution"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Dodatek k ustanovi">
                    <validation-provider #default="{errors}" name="Dodatek k ustanovi" rules="max:50">
                        <b-form-input v-model="institutionObject.institution_addition"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer="{ hide }">
            <b-button variant="outline-primary" @click="hide">
                Zapri
            </b-button>
            <b-button variant="primary" @click="editing ? edit() :add()">
                {{ editing ? 'Uredi' : 'Dodaj' }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import {BButton, BModal, BFormGroup, BFormInput} from 'bootstrap-vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {email, required, min, max, password} from '@core/utils/validations/validations'

    export default {
        components: {
            BModal,
            BButton,
            BFormGroup,
            BFormInput,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                institutionObject: {
                    id:0,
                    institution: '',
                    institution_addition: '',
                    visible: true
                },
                editing :false,
                required,
                email,
                min,
                max,
                password
            }
        },
        methods: {
            show(data = null) {
                if (data) {
                    this.editing = true
                    this.institutionObject = JSON.parse(JSON.stringify(data))
                }


                this.$refs.modal.show()
            },
            async add() {
                const isValid = await this.$refs.validation.validate()
                if (!isValid) return

                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.post('/api/private/v1/institution/', this.institutionObject)
                    this.$refs.modal.hide()
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno')
                    this.makeEmptyObject()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri dodajanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            async edit() {
                const isValid = await this.$refs.validation.validate()
                if (!isValid) return

                try {
                    this.$store.commit('app/START_LOADING')
                    await this.$http.put(`/api/private/v1/institution/${this.institutionObject.id}`, this.institutionObject)
                    this.$refs.modal.hide()
                    this.$emit('itemEdited', this.institutionObject.id)
                    this.$printSuccess('Urejanje je bilo uspešno')
                    this.makeEmptyObject()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri urejanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            makeEmptyObject() {
                this.institutionObject = this.$options.data.call(this).institutionObject
            }
        },
        computed: {
            title() {
                return this.editing ? 'Uredi ustanovo' : 'Dodaj ustanovo'
            }
        }
    }
</script>